@import url("https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap");
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: border-box;
}

.App {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100vw;
  height: 100vh;
}

#root {
  width: 100vw;
  height: 100vh;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  overflow: hidden;
}

@media screen and (max-width: 800px) {
  body {
    overflow: visible;
  }
}

body h1 {
  font-size: 2vw;
}

@media screen and (max-width: 800px) {
  body h1 {
    font-size: 3vw;
  }
}

body h2 {
  font-size: 1.5vw;
}

body .App {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

body .App .main {
  overflow: hidden;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
  width: 100vw;
  position: absolute;
}

@media screen and (max-width: 800px) {
  body .App .main {
    overflow: visible;
  }
}

body .App .about-page {
  width: 80%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media screen and (max-width: 800px) {
  body .App .about-page {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

body .App .about-page .bottom {
  border: black solid;
  position: fixed;
  top: 75%;
  width: 30%;
  height: 10%;
}

body .App .about {
  width: 40%;
  height: 55%;
}

@media screen and (max-width: 800px) {
  body .App .about {
    display: none !important;
  }
}

body .App .icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

body .App .me {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

body .App .me .unity {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0 7.5%;
  width: 85px;
  height: auto;
}

@media screen and (max-width: 800px) {
  body .App .me .unity {
    margin: 0 5%;
    -webkit-filter: contrast(100);
            filter: contrast(100);
  }
}

body .App .me .fab {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 10vw;
}

@media screen and (max-width: 800px) {
  body .App .me .fab {
    width: 30%;
  }
}

body .App .code-box {
  font-weight: bold;
  font-size: 2.5vw;
  line-height: 1;
  text-align: justify;
  width: 45%;
}

@media screen and (max-width: 800px) {
  body .App .code-box {
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 75vw;
    height: 100vh;
    font-size: 35px;
  }
}

body .App .code-box .about-fin {
  line-height: 1.1;
}

@media screen and (max-width: 800px) {
  body .App .code-box .about-fin {
    word-break: break-all;
  }
}

body .App .code-box .about-fin .highlight-r {
  -webkit-text-decoration: underline #fa5b3d;
          text-decoration: underline #fa5b3d;
}

body .App .code-box .about-fin .highlight-l {
  -webkit-text-decoration: underline #3dfab5;
          text-decoration: underline #3dfab5;
}

body .App .code-box .about-fin .highlight-y {
  -webkit-text-decoration: underline #fabb3d;
          text-decoration: underline #fabb3d;
}

body .App .code-box .wd {
  background: red;
  color: white;
}

body .App .code-box .m {
  background-color: green;
  color: white;
}

body .App .code-box .c {
  background-color: blue;
  color: white;
}

body .App .code-box .ps {
  background-color: hotpink;
  color: white;
}

body .App .links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 20vw;
  position: fixed;
  top: 91.75vh;
  right: 14vw;
  z-index: 100;
}

@media screen and (max-width: 800px) {
  body .App .links {
    top: 0;
    right: 0;
    width: 100vw;
    height: 10%;
    background: white;
  }
}

body .App .links ul {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media screen and (max-width: 800px) {
  body .App .links ul {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 75%;
  }
}

body .App .links ul a {
  padding: 2%;
  margin: 0 0.5vw;
  text-decoration: none;
  color: black;
  font-size: 1.25vw;
  font-weight: bold;
}

@media screen and (max-width: 800px) {
  body .App .links ul a {
    font-size: 4vw;
  }
}

body .App .links ul a:hover {
  background-color: black;
  color: white;
}

body .App .lines {
  position: fixed;
  pointer-events: none;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 800px) {
  body .App .lines {
    display: none;
  }
}

.nav-wrapper {
  background-color: aquamarine;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 10;
}

.nav-wrapper nav {
  height: 100%;
  font-size: 4em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.nav-wrapper nav a {
  text-decoration: none;
  -webkit-text-decoration-color: black;
          text-decoration-color: black;
}

.nav-wrapper nav a:visited {
  color: black;
}

.btn {
  position: fixed;
  top: 10%;
  z-index: 15;
  width: 10%;
  height: 2%;
}

.projects-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100vw;
  height: 100vh;
}

@media screen and (max-width: 800px) {
  .projects-container {
    margin-top: 100%;
  }
}

.projects-container:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
}

.projects-container video {
  position: absolute;
  width: auto;
  height: auto;
  z-index: -100;
  min-width: 100%;
  min-height: 100%;
}

.projects-container .projects-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
  overflow-y: hidden;
  overflow-x: hidden;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 75%;
  height: 75%;
  z-index: 110;
}

@media screen and (max-width: 800px) {
  .projects-container .projects-wrapper {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    width: 90vw;
    height: 125vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.projects-container .projects-wrapper .proj-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  width: 50%;
  height: 10%;
}

.projects-container .projects-wrapper button {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  width: 100%;
  height: 100%;
  border: black solid 4px;
  padding: 0;
  font-size: 1rem;
  font-weight: bold;
}

.projects-container .projects-wrapper .more-info {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 49.5%;
  height: 49%;
  margin: 2px;
  border: white solid 4px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 1% 1% 1%;
}

@media screen and (max-width: 800px) {
  .projects-container .projects-wrapper .more-info {
    font-size: 3.5vw;
    width: 100%;
    height: 25%;
  }
}

.projects-container .projects-wrapper .more-info h2 {
  border-bottom: solid black;
  font-weight: bold;
}

@media screen and (max-width: 800px) {
  .projects-container .projects-wrapper .more-info h2 {
    font-size: 4vw;
  }
}

.projects-container .projects-wrapper .more-info ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 50%;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.projects-container .projects-wrapper .more-info ul li {
  margin-top: 2vh;
  text-align: center;
  width: 100%;
  font-size: 1vw;
  font-weight: bold;
}

@media screen and (max-width: 800px) {
  .projects-container .projects-wrapper .more-info ul li {
    font-size: 3.5vw;
    margin-top: 1vh;
  }
}

.projects-container .projects-wrapper .more-info .blk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-decoration: none;
  color: black;
  cursor: pointer;
  width: 12%;
  height: 10%;
  border: black solid;
  margin-bottom: 1vh;
  font-size: 1vw !important;
  font-size: 1rem;
  font-weight: bold;
  z-index: 110;
}

@media screen and (max-width: 800px) {
  .projects-container .projects-wrapper .more-info .blk {
    font-size: 3vw !important;
    width: 15%;
  }
}

.projects-container .projects-wrapper .more-info .blk:hover {
  background-color: #fa5b3d;
  color: black;
}

.projects-container .projects-wrapper .title {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 2px;
  width: 49.5%;
  height: 49%;
  border: black solid 4px;
  overflow: hidden;
}

@media screen and (max-width: 800px) {
  .projects-container .projects-wrapper .title {
    margin-bottom: 5%;
    width: 100%;
    height: 25%;
  }
}

.projects-container .projects-wrapper .title .toggle {
  font-size: 0.8vw;
  position: absolute;
  width: 15%;
  height: 10%;
  top: 85%;
  cursor: pointer;
  z-index: 150;
  overflow: hidden;
}

@media screen and (max-width: 800px) {
  .projects-container .projects-wrapper .title .toggle {
    font-size: 1.7vw;
  }
}

.projects-container .projects-wrapper .title .ani {
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 151;
  pointer-events: none;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.projects-container .projects-wrapper .title .ani1 {
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 151;
  pointer-events: none;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.projects-container .projects-wrapper .title .ani2 {
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 151;
  pointer-events: none;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.projects-container .projects-wrapper .title h2 {
  text-align: center;
}

.projects-container .projects-wrapper .title .more {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.projects-container .projects-wrapper .project-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 20;
}

.projects-container .projects-wrapper .project-card img {
  width: 100%;
  height: 100%;
  z-index: -2;
}

.projects-container .projects-wrapper .project-card video {
  width: 100%;
  height: 100%;
  z-index: -2;
}

.projects-container .projects-wrapper .project-card .description {
  font-size: 1.5em;
  width: 50%;
}

.contact-container {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100vw;
  height: 100vh;
}

.contact-container video {
  position: absolute;
  width: auto;
  height: auto;
  z-index: -100;
  min-width: 100%;
  min-height: 100%;
}

.contact-container .contact-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: white;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 25%;
  height: 50%;
  border: black solid;
  overflow: hidden;
  padding: 1%;
  padding-bottom: 0;
}

@media screen and (max-width: 800px) {
  .contact-container .contact-wrapper {
    width: 75%;
    font-size: 2vw;
  }
}

.contact-container .contact-wrapper .social-links {
  border-top: solid black;
  margin-top: 5%;
  height: 20%;
  width: 110%;
  background: lightgray;
  margin-bottom: 0%;
}

.contact-container .contact-wrapper span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 2%;
  height: 10%;
  width: 100%;
}

.contact-container .contact-wrapper span .contact-me {
  margin-bottom: 9%;
}

.contact-container .contact-wrapper span a {
  padding: 2%;
  font-size: 1vw;
  text-decoration: none;
  text-align: center;
  color: black;
  font-weight: bold;
  width: 5vw;
}

@media screen and (max-width: 800px) {
  .contact-container .contact-wrapper span a {
    width: 15vw;
    font-size: 3vw;
  }
}

.contact-container .contact-wrapper span a:hover {
  background: #fa5b3d;
}

.contact-container .contact-wrapper span .social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 90%;
  height: 100%;
}

.contact-container form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
  height: 100%;
}

.contact-container form .btn-stuff {
  width: 100%;
  height: 15%;
}

.contact-container form .btn-stuff .submit {
  font-size: 1.25vw;
}

@media screen and (max-width: 800px) {
  .contact-container form .btn-stuff .submit {
    font-size: 3vw;
  }
}

.contact-container form .btn-stuff button {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  width: 100%;
  height: 100%;
  border: black solid;
  background: #fa5b3d;
  margin-top: 1%;
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
}

.contact-container form .btn-stuff button:active {
  color: #fa5b3d;
  background: black;
}

.contact-container form .form-stuff {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  height: 17%;
}

.contact-container form .form-stuff input {
  border: 4px solid black;
  height: 90%;
  font-size: 1vw;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
}

@media screen and (max-width: 800px) {
  .contact-container form .form-stuff input {
    font-size: 3vw;
  }
}

.contact-container form .form-stuff textarea {
  border: 4px solid black;
  height: 100%;
  font-size: 1vw;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
}

@media screen and (max-width: 800px) {
  .contact-container form .form-stuff textarea {
    font-size: 3vw;
  }
}

.msg {
  height: 50% !important;
}

.activeLink {
  text-decoration: underline !important;
}

.ani-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  height: 100%;
  color: black;
}

.ani-wrapper li {
  margin: 1vw 0 0 0;
}

.ani-wrapper h1 {
  text-align: center;
  width: 75%;
  color: #fa5b3d;
  font-weight: bold;
  font-size: 1.5vw;
}

@media screen and (max-width: 800px) {
  .ani-wrapper h1 {
    font-size: 4vw;
  }
}

.ani-wrapper .ani-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 1%;
}

.ani-wrapper .ani-content .ani-description {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 45%;
  height: 80%;
}

.ani-wrapper .ani-content .goal {
  font-size: 1vw;
  border-top: black solid;
}

@media screen and (max-width: 800px) {
  .ani-wrapper .ani-content .goal {
    font-size: 2.5vw;
  }
}

.ani-wrapper .ani-content .ani-skills {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 80%;
  width: 45%;
}

.ani-wrapper .ani-content .ani-skills .languages {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-top: black solid;
  height: 50%;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 0 0 10px 0;
}

.ani-wrapper .ani-content .ani-skills .languages li {
  font-size: 0.8vw;
  margin-right: 1vw;
}

@media screen and (max-width: 800px) {
  .ani-wrapper .ani-content .ani-skills .languages li {
    font-size: 2.5vw;
  }
}

.ani-wrapper .ani-content .ani-skills .apis {
  font-size: 0.8vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-top: black solid;
  height: 100%;
}

@media screen and (max-width: 800px) {
  .ani-wrapper .ani-content .ani-skills .apis {
    font-size: 2.5vw;
  }
}

.ani-btns {
  position: absolute;
  height: 10%;
  top: 85%;
  z-index: 157;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 50%;
  height: 10%;
}

.ani-btns a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-decoration: none;
  color: black;
  cursor: pointer;
  width: 25%;
  height: 100%;
  border: black solid;
  background: #fa5b3d;
  font-size: 1vw;
  font-weight: bold;
}

@media screen and (max-width: 800px) {
  .ani-btns a {
    font-size: 2vw;
  }
}

.ani-btns a:hover {
  background-color: white;
  color: black;
}

.ani-btns .blk {
  font-size: 1vw;
  background-color: white;
  color: black;
}

@media screen and (max-width: 800px) {
  .ani-btns .blk {
    font-size: 2vw;
  }
}

.ani-btns .blk:hover {
  background-color: #fa5b3d;
  color: black;
}

@media screen and (max-width: 800px) {
  #myVideo {
    display: none !important;
    visibility: hidden !important;
  }
}

.subsection {
  font-weight: 600;
}
